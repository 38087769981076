import { OUTPUT_DATE_FORMAT } from "@uxf/ui/date-picker-input";
import dayjs from "dayjs";

export const today = dayjs();

export const tomorrow = dayjs().add(1, "day");

export const formattedToday = today.format(OUTPUT_DATE_FORMAT);

export const formattedTomorrow = tomorrow.format(OUTPUT_DATE_FORMAT);
